import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Checkout = ({ step, setStep, formData, fastTurnaround }) => {
  const [error, setError] = useState(null);
  const [isButtonsRendered, setIsButtonsRendered] = useState(false); // Track button visibility

  const nextStep = () => {
    setStep(step + 1);
  };

  const pagesSelected = formData.pageCount; // Pages from formData
  const mult = fastTurnaround ? 1.5 : 1;
  const name = formData.nameInput; // Name from formData
  const email = formData.emailInput; // Email from formData

  // Create PayPal Order
  const createOrder = async () => {
    try {
      const response = await fetch("/.netlify/functions/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pages: pagesSelected, mult: mult }),
      });

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const data = await response.json();
      return data.orderID; // Return orderID for PayPalButtons
    } catch (err) {
      console.error("Error creating order:", err);
      alert("An error occurred while creating the order.");
    }
  };

  // Handle Payment Approval
  const onApprove = async (data, actions) => {
    const orderID = data.orderID;
    console.log("Order ID:", orderID); // Log to check if it's valid

    try {
      // Verify payment with your server
      const response = await fetch("/.netlify/functions/verify-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orderID,
          name,
          email,
          pages: pagesSelected,
          mult: mult,
        }),
      });

      const result = await response.json();

      if (response.ok && result.message === "Payment verified successfully") {
        // Capture payment on approval
        const captureResponse = await actions.order.capture();
        console.log("Payment captured:", captureResponse);

        nextStep(); // Proceed to the next step
      } else {
        alert("Payment verification failed. Please try again.");
      }
    } catch (error) {
      alert("An error occurred during the payment process. Please try again.");
    }
  };

  return (
    <div className="checkout-container container">
      <h4 className="fw-bold mb-4 mt-3">Payment Method</h4>
      <h6 className="mb-4">
        Please select from one of the payment methods below:
      </h6>
      {error && <div className="alert alert-danger">{error}</div>}
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, // Use REACT_APP_ prefix for frontend env vars
        }}
      >
        {/* Show loading message until buttons are visible */}
        {!isButtonsRendered && <div>Loading... Please wait</div>}

        <div style={{ display: isButtonsRendered ? "block" : "none" }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={(err) => {
              setError("An error occurred with PayPal. Please try again.");
              console.error("PayPal Error:", err);
            }}
            onInit={() => setIsButtonsRendered(true)} // Mark buttons as rendered
          />
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

export default Checkout;
