import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import JSZip from "jszip";

function DocumentsSection({
  step,
  setStep,
  formData,
  setFormData,
  certifiedSelected,
  setCertifiedSelected,
  files,
  setFiles,
  zipFiles,
  setZipFiles,
  showDocAlert,
  showLangAlert,
  showPageAlert,
  langAlertRef,
  docAlertRef,
  pageAlertRef,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length) {
        // Update files state
        setFiles((previousFiles) => {
          const updatedFiles = [
            ...previousFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
          ];

          // Regenerate the zip with the updated files list
          const zip = new JSZip();
          updatedFiles.forEach((file) => {
            zip.file(file.name, file);
            console.log("Adding to zip:", file.name); // Log file names
          });

          zip.generateAsync({ type: "blob" }).then((blob) => {
            const zippedFiles = new File([blob], "whatever.zip", {
              lastModified: Date.now(),
              type: "application/zip",
            });

            console.log("Generated zip file:", zippedFiles); // Log the zipped file object

            setZipFiles(zippedFiles);
            setFormData((prevFiles) => ({
              ...prevFiles,
              inputFiles: zippedFiles,
            }));
          });

          return updatedFiles; // Return updated list to update `files` state
        });
      }
    },
    [setFiles, setFormData, setZipFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 10000000,
  });

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));

    // Regenerate the zip with remaining files
    const zip = new JSZip();
    files.forEach((file) => zip.file(file.name, file));

    zip.generateAsync({ type: "blob" }).then((blob) => {
      const zippedFiles = new File([blob], "whatever.zip", {
        lastModified: Date.now(),
        type: "application/zip",
      });

      setZipFiles(zippedFiles);
      setFormData((prevFiles) => ({ ...prevFiles, inputFiles: zippedFiles }));
    });
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size >= 1024 && size < 1048576)
      return `${(size / 1024).toFixed(1)} KB`;
    else return `${(size / 1048576).toFixed(1)} MB`;
  };

  return (
    <div className="container orderDocuments my-3">
      <h4 className="fw-bold mb-2">Documents</h4>
      {/*<h6 className="fw-bold mb-3">1. What type of translation do you need?</h6>
      <div className="row">
        <div className="col-8 text-start align-self-center">
          <p className="fw-bold mb-0">Certified Translation</p>
          <p className="lightText">
            Word-for-word human translation of documents with certification for
            official use.
          </p>
        </div>
        <div className="col-2 text-end align-self-center">
          <button
            type="button"
            className={`btn ${
              certifiedSelected
                ? "btn-primary selectBtn"
                : "btn-outline-primary selectBtn"
            } `}
            onClick={() => setCertifiedSelected((certifiedSelected = true))}
          >
            {certifiedSelected === true ? "Selected" : "Select"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-8 text-start align-self-center">
          <p className="fw-bold mb-0">Standard Translation</p>
          <p className="lightText">
            Human translation of documents and text-based content for personal
            use.
          </p>
        </div>
        <div className="col-2 text-end align-self-center">
          <button
            type="button"
            className={`btn ${
              certifiedSelected
                ? "btn-outline-primary selectBtn"
                : "btn-primary selectBtn"
            } `}
            onClick={() => setCertifiedSelected((certifiedSelected = false))}
          >
            {certifiedSelected === true ? "Select" : "Selected"}
          </button>
        </div>
      </div>*/}
      <div></div>
      <h6 className=" mb-3">
        1. What languages do you need us to translate from and into? *
      </h6>
      <p className="fw-bold">What language is your document in?</p>
      <div className="mb-2">
        <select
          className="form-select"
          id="SL"
          aria-label="Default select example"
          name="Source-Language[]"
          required
          form="orderForm"
          value={formData["Source-Language[]"]}
          onChange={(e) =>
            setFormData({ ...formData, "Source-Language[]": e.target.value })
          }
        >
          <option value="" disabled selected id="placeholderSelect">
            Translate From
          </option>
          <option value="Arabic" name="Arabic">
            Arabic
          </option>
          <option value="Afrikaans" name="Afrikaans">
            Afrikaans
          </option>
          <option value="Czech" name="Czech">
            Czech
          </option>
          <option value="Danish" name="Danish">
            Danish
          </option>
          <option value="Dutch" name="Dutch">
            Dutch
          </option>
          <option value="English" name="English">
            English
          </option>
          <option value="French" name="French">
            French
          </option>
          <option value="Finnish" name="Finnish">
            Finnish
          </option>
          <option value="German" name="German">
            German
          </option>
          <option value="Greek" name="Greek">
            Greek
          </option>
          <option value="Hindi" name="Hindi">
            Hindi
          </option>
          <option value="Hebrew" name="Hebrew">
            Hebrew
          </option>
          <option value="Hungarian" name="Hungarian">
            Hungarian
          </option>
          <option value="Italian" name="Italian">
            Italian
          </option>
          <option value="Indonesian" name="Indonesian">
            Indonesian
          </option>
          <option value="Japanese" name="Japanese">
            Japanese
          </option>
          <option value="Korean" name="Korean">
            Korean
          </option>
          <option value="Lithuanian" name="Lithuanian">
            Lithuanian
          </option>
          <option value="Norwegian" name="Norwegian">
            Norwegian
          </option>
          <option value="Negerian" name="Negerian">
            Nigarian
          </option>
          <option value="Portuguese" name="Portuguese">
            Portuguese
          </option>
          <option value="Polish" name="Polish">
            Polish
          </option>
          <option value="Persian" name="Persian">
            Persian
          </option>
          <option value="Russian" name="Russian">
            Russian
          </option>
          <option value="Romanian" name="Romanian">
            Romanian
          </option>
          <option value="Spanish" name="Spanish">
            Spanish
          </option>
          <option value="Simplified chinese" name="Simplified chinese">
            Simplified chinese
          </option>
          <option value="Swedish" name="Swedish">
            Swedish
          </option>
          <option value="Thai" name="Thai">
            Thai
          </option>
          <option value="Turkish" name="Turkish">
            Turkish
          </option>
        </select>
      </div>
      <p className="fw-bold">What language do you want it translated to?</p>
      <div className="mb-2">
        <select
          className="form-select"
          id="TL"
          aria-label="Default select example"
          name="Target-Language[]"
          required
          form="orderForm"
          value={formData["Target-Language[]"]}
          onChange={(e) =>
            setFormData({ ...formData, "Target-Language[]": e.target.value })
          }
        >
          <option value="" disabled selected>
            Translate To
          </option>
          <option value="Arabic" name="Arabic">
            Arabic
          </option>
          <option value="Afrikaans" name="Afrikaans">
            Afrikaans
          </option>
          <option value="Czech" name="Czech">
            Czech
          </option>
          <option value="Danish" name="Danish">
            Danish
          </option>
          <option value="Dutch" name="Dutch">
            Dutch
          </option>
          <option value="English" name="English">
            English
          </option>
          <option value="French" name="French">
            French
          </option>
          <option value="Finnish" name="Finnish">
            Finnish
          </option>
          <option value="German" name="German">
            German
          </option>
          <option value="Greek" name="Greek">
            Greek
          </option>
          <option value="Hindi" name="Hindi">
            Hindi
          </option>
          <option value="Hebrew" name="Hebrew">
            Hebrew
          </option>
          <option value="Hungarian" name="Hungarian">
            Hungarian
          </option>
          <option value="Italian" name="Italian">
            Italian
          </option>
          <option value="Indonesian" name="Indonesian">
            Indonesian
          </option>
          <option value="Japanese" name="Japanese">
            Japanese
          </option>
          <option value="Korean" name="Korean">
            Korean
          </option>
          <option value="Lithuanian" name="Lithuanian">
            Lithuanian
          </option>
          <option value="Norwegian" name="Norwegian">
            Norwegian
          </option>
          <option value="Negerian" name="Negerian">
            Nigarian
          </option>
          <option value="Portuguese" name="Portuguese">
            Portuguese
          </option>
          <option value="Polish" name="Polish">
            Polish
          </option>
          <option value="Persian" name="Persian">
            Persian
          </option>
          <option value="Russian" name="Russian">
            Russian
          </option>
          <option value="Romanian" name="Romanian">
            Romanian
          </option>
          <option value="Spanish" name="Spanish">
            Spanish
          </option>
          <option value="Simplified chinese" name="Simplified chinese">
            Simplified chinese
          </option>
          <option value="Swedish" name="Swedish">
            Swedish
          </option>
          <option value="Thai" name="Thai">
            Thai
          </option>
          <option value="Turkish" name="Turkish">
            Turkish
          </option>
        </select>
      </div>
      {showLangAlert && (
        <div
          ref={langAlertRef}
          className="alert alert-danger mt-2"
          role="alert"
          id="languageAlert"
        >
          <p>The source and target language cannot be the same!</p>
        </div>
      )}
      <h6 className="mt-5 mb-3">2. How many pages is your document? *</h6>
      <div className="row">
        <div className="col">
          <div>
            {showPageAlert && (
              <div
                ref={pageAlertRef}
                className="alert alert-danger mt-2"
                role="alert"
                id="pageAlert"
              >
                <p>The page count must be at least 1!</p>
              </div>
            )}
            <label
              className="form-check-label mb-2 fw-bold"
              htmlFor="pageCount"
            >
              Page Count
            </label>
          </div>
          <input
            type="number"
            className="form-control"
            id="pageCount"
            name="pageCount"
            form="orderForm"
            required
            value={formData.pageCount}
            onChange={(e) =>
              setFormData({ ...formData, pageCount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="">
        <p className="lightText smallText pt-2">
          Certified translation pricing is based on a per page basis.
        </p>
      </div>
      <br />

      {/* File Input */}

      <h6 className="mt-4 fw-bold">4. Upload your Document(s): *</h6>
      <label className="form-check-label mt-2" htmlFor="docs">
        <div className="row">
          <p className="smallText ">
            To avoid any delays, please make sure your document is readable and
            of decent quality
          </p>
        </div>
      </label>

      <div className="drop">
        <div className="dropzone" id="dropzone" {...getRootProps()}>
          <input className="drop" name="inputFiles" {...getInputProps()} />

          <>
            <div className="text-center icon">
              <FontAwesomeIcon icon={faCloudArrowUp} className="cloudIcon " />
            </div>
            <p className="text-center ">
              Drag and drop some files here, or{" "}
              <span
                className=""
                style={{ color: "blue", textDecoration: "underline" }}
              >
                click
              </span>{" "}
              to select files
            </p>
          </>
        </div>
      </div>
      {showDocAlert && (
        <div
          className="alert alert-danger mt-2"
          role="alert"
          id="documentAlert"
          ref={docAlertRef}
        >
          <p>At least 1 file must be uploaded!</p>
        </div>
      )}
      {/* Add links to scroll to alerts */}

      <ul className="px-2">
        <p className="pt-4 fw-bold">Uploaded File(s):</p>
        {files.map((file) => (
          <div key={file.name}>
            <div className="row fileRow">
              <div className="col-10">
                <p className="fw-bold fileName">{file.name}</p>
                <p className="fileSize">{formatFileSize(file.size)}</p>
              </div>
              <div className="col-2 d-flex align-items-center justify-content-end">
                <button
                  className="btn text-end py-auto"
                  onClick={() => removeFile(file.name)}
                >
                  <p>
                    <FontAwesomeIcon icon={faTrash} className="redIcon" />
                  </p>
                </button>
              </div>
            </div>
          </div>
        ))}
      </ul>

      {/* End of File Input */}

      <h6 className="mt-5 fw-bold mb-3">
        5. Notes<span className="fw-light"> (Optional)</span>
      </h6>
      <div>
        <label className="form-check-label mb-2 fw-bold" htmlFor="pageCount">
          Add any extra notes or special instructions that you might have
        </label>
      </div>
      <input
        type="text"
        placeholder="Type your notes"
        className="form-control"
        id="notes"
        name="notes"
        form="orderForm"
        value={formData.notes}
        onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
      />

      <br />
      <button
        type="submit"
        className="btn btn-success nextBtn mt-1"
        id="ToOptions"
      >
        Continue to Options →
      </button>
    </div>
  );
}

export default DocumentsSection;
