import React from "react";

function ContactSection({ formData, setFormData }) {
  return (
    <div className={`container pt-3 orderContact`}>
      <input type="hidden" name="form-name" value="orderForm"></input>
      <h4 className="fw-bold mb-2">Contact Information</h4>
      <h6 className="mb-4">Please enter your contact details:</h6>

      <div className="mb-3 ">
        <label htmlFor="nameInput" className="form-label mb-0">
          <p className="fw-bold">Full name *</p>
        </label>
        <input
          type="text"
          className="form-control"
          id="nameInput"
          name="nameInput"
          form="orderForm"
          placeholder="Enter Your Full Name"
          required
          value={formData.nameInput}
          onChange={(e) =>
            setFormData({ ...formData, nameInput: e.target.value })
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="emailInput" className="form-label mb-0">
          <p className="fw-bold">Email *</p>
        </label>
        <input
          type="email"
          className="form-control"
          name="emailInput"
          id="emailInput"
          form="orderForm"
          placeholder="Enter Your E-mail"
          value={formData.emailInput}
          onChange={(e) =>
            setFormData({ ...formData, emailInput: e.target.value })
          }
          required
        />
      </div>
    </div>
  );
}

export default ContactSection;
