import React from "react";

function OptionsSection({
  step,
  setStep,
  fastTurnaround,
  setFastTurnaround,
  formData,
  setCheckout,
  certifiedSelected,
}) {
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const checkFastTurnaround = () => {
    console.log("clicked");
    setFastTurnaround(!fastTurnaround);
  };

  const handleClick = () => {
    nextStep();
  };

  return (
    <div className="container orderOptions">
      <h4 className="mt-4 fw-bold mb-4">
        Which optional services do you need?
      </h4>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-9">
            <label className="form-check-label" htmlFor="fastTurnaround">
              <h6 className="fw-bold me-2">Expedited Turnaround</h6>
            </label>
            <p>
              Prioritize your order for faster delivery—turnaround time is
              reduced by 100%, with a 50% price increase
            </p>
          </div>

          <div className="col-3 d-flex justify-content-end">
            <div className="form-check form-switch">
              <input
                className={`form-check-input ${
                  fastTurnaround ? "btn-primary" : "btn-outline-primary"
                }`}
                type="checkbox"
                id="fastTurnaround"
                checked={fastTurnaround}
                onChange={checkFastTurnaround} // This will handle the state change
                style={{ transform: "scale(1.5)" }} // Adjust the scale for a larger toggle
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6 text-end">
          <button
            type="button"
            className="btn btn-success mb-3 nextBtn"
            onClick={handleClick}
            id="ToCheckout"
          >
            Continue to Checkout
          </button>
        </div>
        <div className="col-md-6 text-end">
          <button
            type="button"
            className="btn btn-outline-primary mb-5 nextBtn"
            onClick={prevStep}
            id="BackToDocs"
          >
            Back to Documents
          </button>
        </div>
      </div>
    </div>
  );
}

export default OptionsSection;
