import React from "react";

const ThankYou = () => {
  return (
    <div className="container text-center my-5" id="thankYou">
      <h1 className="display-4">Thank You for Your Order!</h1>

      <p className="lead mt-4">
        We're excited to confirm that your payment has been successfully
        processed. <br />
        <br /> A confirmation email has been sent to you, which will include
        details about your order and the next steps.
      </p>

      <footer className="mt-5">
        <p className="text-muted">
          Thank you again for choosing EA Translations! We appreciate your
          business and look forward to serving you again soon.
        </p>
      </footer>
    </div>
  );
};

export default ThankYou;
