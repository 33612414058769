import React from "react";
import logo from "../img/logo.png";

function Navbar({ step, setStep }) {
  let documentsClassHandler;
  let optionsClassHandler;
  let checkoutClassHandler;

  if (step === 0) {
    documentsClassHandler = " active";
    optionsClassHandler = " ";
    checkoutClassHandler = " ";
  } else if (step === 1) {
    documentsClassHandler = " completed";
    optionsClassHandler = " active";
    checkoutClassHandler = " ";
  } else if (step === 2) {
    documentsClassHandler = " completed";
    optionsClassHandler = " completed";
    checkoutClassHandler = " active";
  } else if (step === 3) {
    documentsClassHandler = " completed";
    optionsClassHandler = " completed";
    checkoutClassHandler = " completed";
  }

  return (
    <>
      <div id="navbar">
        <nav>
          <div className="container-fluid">
            <div className="row">
              {/* Logo Section */}
              <div className="col-md text-start">
                <a href="/../index.html">
                  <img
                    src={logo}
                    alt="Arabic translation services"
                    className="logo"
                  />
                </a>
              </div>

              {/* Stepper Section */}
              <div className="col-md text-center">
                <div className="me-0 stage-counter">
                  <div>
                    <div className="stepper-wrapper">
                      <div className={`stepper-item${documentsClassHandler}`}>
                        <div className="step-counter"></div>
                        <div className="step-name fw-bold">Documents</div>
                      </div>
                      <div className={`stepper-item${optionsClassHandler}`}>
                        <div className="step-counter"></div>
                        <div className="step-name fw-bold">Options</div>
                      </div>
                      <div className={`stepper-item${checkoutClassHandler}`}>
                        <div className="step-counter"></div>
                        <div className="step-name fw-bold">Checkout</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
