import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

// Example Query for Getting the first product

function SummarySection({ formData, fastTurnaround, certifiedSelected }) {
  // Responses from the graphQL requests (using Apollo)

  return (
    <>
      <div className="col-md secCol">
        <div className="container py-5 px-3">
          <h4 className="text-start fw-bold mb-4">Order Summary</h4>

          <div className="text-start">
            <div className="row">
              <div className="col-8 ">
                <h6 className="fw-bold">
                  {certifiedSelected
                    ? "Certified Document Translation"
                    : "Standard Document Translation"}
                </h6>
              </div>
              <div className="col text-end">
                <h6 className="fw-bold">
                  {" "}
                  $
                  {fastTurnaround
                    ? (formData.pageCount * 1.5 * 28.95).toFixed(2)
                    : (formData.pageCount * 28.95).toFixed(2)}
                </h6>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col lightText smallText">Source Language:</div>
              <div className="col text-end lightText smallText">
                {formData["Source-Language[]"] || "Not Selected"}
              </div>
            </div>
            <div className="row mb-1">
              <div className="col lightText smallText">Target Language:</div>
              <div className="col text-end lightText smallText">
                {formData["Target-Language[]"] || "Not Selected"}
              </div>
            </div>
            <div className="row mb-1">
              <div className="col lightText smallText">Pages:</div>
              <div className="col text-end lightText smallText">
                {formData.pageCount || 0}
              </div>
            </div>
            <div className="row mb-1">
              <div className="col lightText smallText">Delivery Method:</div>
              <div className="col text-end lightText smallText">Email</div>
            </div>
            <div className="row mb-1">
              <div className="col lightText smallText">Delivery Timeline:</div>
              <div className="col text-end lightText smallText">
                {fastTurnaround ? "12 Hours or less" : "24 Hours or less"}
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col">
                <h6 className="fw-bold">Certificate of Accuracy</h6>
              </div>
              <div className="col text-end">
                <h6 class="fw-bold">$0.00</h6>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col">
                <h5 class="fw-bold">Total</h5>
              </div>
              <div className="col text-end">
                <h5 className="fw-bold">
                  {" "}
                  $
                  {fastTurnaround
                    ? (formData.pageCount * 1.5 * 28.95).toFixed(2)
                    : (formData.pageCount * 28.95).toFixed(2)}
                </h5>
              </div>
            </div>
            <br></br>
          </div>

          <div className="text-start mb-5">
            <span className="d-inline-flex align-items-center">
              <FontAwesomeIcon icon={faLock} />
              <h6 className="fw-bold ms-2 mb-0">Secure and Private</h6>
            </span>
            <p>
              All data processed by our site, including uploaded files, is
              encrypted. Only essential staff with signed NDAs can access your
              information.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SummarySection;
