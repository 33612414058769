import React, { useState, useRef } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import ContactSection from "./components/ContactSection";
import DocumentsSection from "./components/DocumentsSection";
import OptionsSection from "./components/OptionsSection";
import SummarySection from "./components/SummarySection";
import Checkout from "./components/Checkout";
import ThankYou from "./components/ThankYou";

function App() {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const nextStep = () => {
    // Show loading screen
    setLoading(true);

    // Simulate loading delay
    setTimeout(() => {
      setLoading(false); // Hide loading screen
      setStep(step + 1); // Move to the next step
    }, 2500); // Adjust delay as needed (2 seconds in this example)
  };

  const [formData, setFormData] = useState({
    nameInput: "",
    emailInput: "",
    pageCount: "0",
    "Source-Language[]": "",
    "Target-Language[]": "",
    inputFiles: "",
    notes: "",
  });

  const [files, setFiles] = useState([]);

  const [zipFiles, setZipFiles] = useState();

  const [fastTurnaround, setFastTurnaround] = useState(false);

  const [certifiedSelected, setCertifiedSelected] = useState(true);

  const [checkout, setCheckout] = useState();

  const [showLangAlert, setShowLangAlert] = useState(false); // State to show/hide the alert
  const [showDocAlert, setShowDocAlert] = useState(false); // State to show/hide the alert
  const [showPageAlert, setShowPageAlert] = useState(false); // State to show/hide the alert
  // Create refs for each alert
  const langAlertRef = useRef(null);
  const docAlertRef = useRef(null);
  const pageAlertRef = useRef(null);

  function submitForm(e) {
    e.preventDefault(); // Move this to the top to prevent default behavior early

    // Defensive check to ensure formData exists
    if (
      !formData ||
      !formData["Source-Language[]"] ||
      !formData["Target-Language[]"]
    ) {
      console.error("Source or target language is not properly set.");
      return;
    }

    // Get the source and target languages from the formData
    const sourceLang = formData["Source-Language[]"];
    const targetLang = formData["Target-Language[]"];

    // Check if sourceLang and targetLang are the same
    if (sourceLang === targetLang) {
      console.log("Warning: Source and target languages cannot be the same.");
      setShowLangAlert(true);

      // Scroll to language alert after it is rendered
      setTimeout(() => {
        const langAlert = document.getElementById("languageAlert");
        if (langAlert) {
          const topPosition =
            langAlert.getBoundingClientRect().top + window.scrollY - 40; // Adjust the -20 to your desired margin
          window.scrollTo({ top: topPosition, behavior: "smooth" }); // Scroll with slight margin
        }
      }, 0); // Scroll immediately after rendering

      setTimeout(() => {
        setShowLangAlert(false); // Hide after 3 seconds
      }, 3000);
      return; // Exit the function
    }
    // Check for page count
    const pageCount = formData.pageCount;
    if (Number(pageCount) === 0) {
      setShowPageAlert(true);
      console.log("pagecount 0 detected");

      // Scroll to document alert after it is rendered
      setTimeout(() => {
        const pageAlert = document.getElementById("pageAlert");
        if (pageAlert) {
          const topPosition =
            pageAlert.getBoundingClientRect().top + window.scrollY - 40; // Adjust the -20 to your desired margin
          window.scrollTo({ top: topPosition, behavior: "smooth" }); // Scroll with slight margin
        }
      }, 0); // Scroll immediately after rendering

      setTimeout(() => {
        setShowDocAlert(false); // Hide after 3 seconds
      }, 3000);
      return; // Exit the function
    }
    // Check for files before submission
    if (!files || files.length === 0) {
      console.log("At least one file is required.");
      setShowDocAlert(true);

      // Scroll to document alert after it is rendered
      setTimeout(() => {
        const docAlert = document.getElementById("documentAlert");
        if (docAlert) {
          const topPosition =
            docAlert.getBoundingClientRect().top + window.scrollY - 40; // Adjust the -20 to your desired margin
          window.scrollTo({ top: topPosition, behavior: "smooth" }); // Scroll with slight margin
        }
      }, 0); // Scroll immediately after rendering

      setTimeout(() => {
        setShowDocAlert(false); // Hide after 3 seconds
      }, 3000);
      return; // Exit the function
    }

    // Encode form data for submission
    const encodeData = (data) => {
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        formData.append(k, data[k]);
      });
      return formData;
    };

    fetch("/", {
      method: "POST",
      body: encodeData({ "form-name": "orderForm", ...formData }),
    })
      .then(() => console.log("Form submitted:", formData))
      .catch((error) => {
        console.error("Submission error:", error);
        alert("There was an error submitting the form.");
      });

    // Move to the next step only if validation passes
    nextStep();
  }

  return (
    <div className="App">
      <Navbar step={step} setStep={setStep} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md text-start" id="leftCol">
            <div className="">
              {loading ? (
                <div className="loading-screen">
                  <div className="loading-text text-center">
                    Uploading documents, please wait...
                  </div>
                  <div className="progress-bar-container">
                    <div className="progress-bar"></div>
                  </div>
                </div>
              ) : (
                <form
                  id="orderForm"
                  onSubmit={submitForm}
                  data-netlify="true"
                  name="orderForm"
                  method="POST"
                  netlify
                >
                  {step === 0 ? (
                    <>
                      <div className="card border-0">
                        <div className="card-body">
                          <ContactSection
                            step={step}
                            setStep={setStep}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>

                      <div className="card border-0 mb-3">
                        <div className="card-body">
                          <DocumentsSection
                            step={step}
                            setStep={setStep}
                            formData={formData}
                            setFormData={setFormData}
                            certifiedSelected={certifiedSelected}
                            setCertifiedSelected={setCertifiedSelected}
                            files={files}
                            setFiles={setFiles}
                            zipFiles={zipFiles}
                            setZipFiles={setZipFiles}
                            showDocAlert={showDocAlert}
                            showLangAlert={showLangAlert}
                            showPageAlert={showPageAlert}
                            docAlertRef={docAlertRef}
                            langAlertRef={langAlertRef}
                            pageAlertRef={pageAlertRef}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {step === 1 ? (
                    <div className="card mb-5">
                      <div className="card-body">
                        <OptionsSection
                          step={step}
                          setStep={setStep}
                          fastTurnaround={fastTurnaround}
                          setFastTurnaround={setFastTurnaround}
                          formData={formData}
                          checkout={checkout}
                          setCheckout={setCheckout}
                          certifiedSelected={certifiedSelected}
                          setCertifiedSelected={setCertifiedSelected}
                        />
                      </div>
                    </div>
                  ) : null}

                  {step === 2 ? (
                    <div className="card mb-5">
                      <div className="card-body">
                        <Checkout
                          step={step}
                          setStep={setStep}
                          checkout={checkout}
                          formData={formData}
                          files={files}
                          fastTurnaround={fastTurnaround}
                        />
                      </div>
                    </div>
                  ) : null}
                  {step === 3 ? <ThankYou /> : null}
                </form>
              )}
            </div>
          </div>

          <div className="col-md text-end" id="rightCol">
            <div className="">
              <div class="">
                <SummarySection
                  formData={formData}
                  fastTurnaround={fastTurnaround}
                  certifiedSelected={certifiedSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
